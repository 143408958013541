import React, { useEffect } from "react"
import { Link, navigate } from "gatsby"
import Seo from "../components/seo"
import { useState } from "react"
import Axios from "axios"
import styled from "styled-components"
import { useForm } from "react-hook-form"
//Context hook
import { useContext } from "react"
import { GlobalAuthContext } from "../Contexts/GlobalAuthContext"
import CardContainer from "../components/register-login-reset-popup/cardContainer"
import FormContainer from "../components/register-login-reset-popup/formContainer"
import Alert from "../components/register-login-reset-popup/alert"
import LinkElement from "../components/register-login-reset-popup/link"
import PopupName from "../components/register-login-reset-popup/popupName"
import { Cookies } from "react-cookie-consent"
import Eye from "../icons/forms/eye.svg"
import EyeOff from "../icons/forms/eyeOff.svg"
//css layout for login
const LoginStyle = styled.section`
  display: flex;
  flex-direction: column;
  height: 85vh;
  justify-content: center;
  align-items: center;
  .forgot-password {
    align-self: flex-end;
    font-family: Work Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #007de6;
    text-decoration: none;
  }

  .card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 1000px;
    height: 500px;
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    @media (max-width: 560px) {
      width: 340px;
    }

    .form-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;

      /* @media (max-width: 560px) {
        width: 90%;
      } */

      .form-name {
        align-self: center;
        width: 95px;
        height: 40px;
        font-family: Work Sans;
        font-style: normal;
        font-weight: 500;
        font-size: 36px;
        line-height: 40px;
        text-align: center;
        color: #111111;
        flex: none;
        flex-grow: 0;
        margin: 32px 0px;
      }

      label {
        align-self: flex-start;
        font-family: Work Sans;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #111111;
        margin: 8px 0px;
        @media (max-width: 560px) {
          width: max-content;
        }
      }

      input {
        width: 384px;
        height: 51px;

        font-size: 16px;
        line-height: 19px;
        border: 1px solid #d8d8d8;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 0px 20px;
        margin: 8px 0px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: black;
        ::placeholder {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          color: #999999;
        }

        @media (max-width: 560px) {
          width: 300px;
        }
      }
      .alert-fail {
        font-family: Work Sans;
        width: 273px;
        height: 24px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: #e44331;
        margin: 0;
        padding: 0;
        align-self: flex-start;
        margin-left: 0px;
        @media (max-width: 675px) {
          width: 273px;
          overflow: hidden;
        }
      }
      .forgot-password {
        align-self: flex-end;
        font-family: Work Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #007de6;
        text-decoration: none;
      }
      .register {
        color: #007de6;
        margin-left: 5px;
        @media (max-width: 560px) {
          width: max-content;
        }
      }
    }
    button {
      color: white;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 16px;
      width: 384px;
      height: 50px;
      left: 0px;
      top: 226px;
      background: #00ca42;
      border-radius: 60px;
      flex: none;
      align-self: center;
      flex-grow: 0;
      margin: 16px 0px;
      border: none;
      cursor: pointer;
      &:hover {
        background: rgb(50, 205, 50);
      }
      @media (max-width: 560px) {
        width: 209px;
      }
    }

    span {
      width: 286px;
      height: 21px;
      font-family: Work Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      color: #222222;
      flex: none;
      flex-grow: 0;
      margin: 24px 0px;

      @media (max-width: 560px) {
        width: max-content;
      }
    }
  }

  .passInput {
    display: flex;
    flex-direction: row;
    width: 100%;
    @media (max-width: 600px) {
      width: 100%;
    }
    .vectorEye {
      width: 0;
    }

    svg {
      position: relative;
      display: inline-block;
      width: 32px;
      height: 32px;
      min-width: 32px;
      min-height: 32px;
      float: right;
      top: 16px;
      right: 16px;
      z-index: 100;
      cursor: pointer;
    }
  }
`

const CapsLock = styled.div`
  display: flex;
  flex-direction: row !important;
  justify-content: flex-start;
  font-size: 12px;
  width: 100%;
  div {
    width: max-content !important;
  }
  b {
    width: max-content;
    ${props => (props.Caps ? `color: rgb(0, 202, 66);` : `color: red;`)}
  }
  margin-bottom: 8px;

  @media (max-width: 768px) {
    display: none !important;
  }
`

function Login() {
  //is button disabled state
  const [isDisabled, setIsDisabled] = useState(false)
  const [passVisible, setPassVisible] = useState("Password")
  const [caps, setCaps] = useState(null)

  //Global context hook
  const { setIsLoggedIn, setUser, setAuthNumber } = useContext(
    GlobalAuthContext
  )

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("isLogged")) === true) {
      navigate("/app/dashboard")
    }
    document.addEventListener("keyup", event => {
      if (event.getModifierState("CapsLock")) {
        setCaps(true)
      } else {
        setCaps(false)
      }
    })
  }, [])

  function login(user, pass) {
    //passing request - this is exception - all others go with /components/api.js
    Axios.post("https://users.licensespring.com/api/v1/auth/login/", {
      email: user + "|pdfpro",
      password: pass,
    })
      .then(response => {
        //delete console.log later in the process and add something that will show if the user is or isn't logged in
        //you can't use local storage in production version - find another solution
        // console.log(response)
        localStorage.setItem("isLogged", JSON.stringify(true))
        localStorage.setItem("isLoggedIn", JSON.stringify(response.data.token))
        localStorage.setItem("user", JSON.stringify(response.data.user))
        localStorage.setItem(
          "username",
          JSON.stringify({
            first_name: response.data.user.first_name,
            last_name: response.data.user.last_name,
          })
        )
        localStorage.setItem(
          "numberOfLicenses",
          JSON.stringify(response.data.user.number_of_licenses)
        )
        setAuthNumber(response.data.user.number_of_licenses)
        setIsLoggedIn(true)
        setUser(response.data.user.first_name)
        //GTM pushing to Data Layer
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: "login",
          userId: `${response.data.user.id}`,
        })
        setIsDisabled(false)
        // if (Cookies.get("checkout")) {
        //   navigate("/checkout")
        //   Cookies.remove("checkout")
        // } else {
        //   navigate("/app/dashboard")
        // }
        navigate("/app/dashboard")
      })
      .catch(error => {
        //delete console log and replace it later with the error message
        console.log(error)
        setIsDisabled(false)
        setError("WrongCreds", {
          type: "required",
          message: "Incorrect email or password! Try again.",
        })
      })
  }

  function akcija(data) {
    console.log("disabled", isDisabled)
    if (isDisabled === false) {
      setIsDisabled(true)
      login(data.Email, data.Password)
    }
  }

  const {
    register,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm()

  return (
    <>
      <Seo title="Login" />

      <LoginStyle>
        <CardContainer>
          <FormContainer>
            <form style={{ margin: `0` }} onSubmit={handleSubmit(akcija)}>
              <PopupName>Login</PopupName>
              <CapsLock Caps={caps}>
                <div>{caps != null && `Caps Lock:`}&nbsp;</div>{" "}
                <b>{caps != null && (caps ? "ON" : "OFF")}</b>
              </CapsLock>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter email"
                {...register("Email", {
                  required: "Email required",
                  onChange: () => clearErrors("WrongCreds"),
                })}
              />
              <label htmlFor="password">Password</label>
              <div className="passInput">
                <input
                  type={passVisible}
                  id="password"
                  name="password"
                  placeholder="Enter password"
                  {...register("Password", {
                    required: "Password required",
                    onChange: () => clearErrors("WrongCreds"),
                  })}
                />
                <div
                  className="vectorEye"
                  onClick={() =>
                    setPassVisible(vis => (vis == "Text" ? "Password" : "Text"))
                  }
                >
                  {passVisible == "Password" && <EyeOff />}
                  {passVisible != "Password" && <Eye />}
                </div>
              </div>
              <div className="alertContainer">
                {Object.values(errors).map(prop => {
                  return <Alert>{prop.message}</Alert>
                })}
              </div>
              <LinkElement to="/password-reset"> Forgot password?</LinkElement>
              <input className="submit" type="submit" value="Log in" />
            </form>
          </FormContainer>
          <span>
            Don't have an account?
            <Link
              // remove this inline style when you remove bootstrap
              style={{
                color: `#007de6`,
                marginLeft: `5px`,
                cursor: `pointer`,
                textDecoration: `none`,
              }}
              className="register"
              to="/register"
            >
              Register
            </Link>
          </span>
        </CardContainer>
      </LoginStyle>
    </>
  )
}

export default Login
