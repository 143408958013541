import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
const LinkStyle = styled(Link)`
  align-self: flex-end;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #007de6;
  text-decoration: none;
`
function LinkElement({ children, to }) {
  return <LinkStyle to={to}>{children}</LinkStyle>
}

export default LinkElement
